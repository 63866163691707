import React from "react"
import styled from "styled-components"
import AccentedButton from "./accented-button"
import getUserContent from "../../user-content"

const Centered = styled.div`
  text-align: center;
`

const TabContent = styled.div`
  padding-top: 5px;
  padding-bottom: 20px;
`

const BackToTop = styled(AccentedButton)`
  display: inline-grid;
  grid-template-columns: auto auto auto;
`

const BackToTopText = styled.div`
  &:before {
    content: "↰";
    display: inline-block;
    transform: rotate(-90deg) scaleX(-1);
  }

  &:after {
    content: "↰";
    display: inline-block;
    transform: rotate(90deg);
  }
`

const scrollToTop = () => {
  window.scrollTo({top: 0, left: 0, behavior: "smooth"})
  document.getElementById("HeaderHomeButton").focus()
}

export default function MainContent(props) {
  const tabs = getUserContent().tabs
  const activeTab = props.tab || tabs[0].slug
  return (
    <div id="MainContent">

      <TabContent>
        {tabs.find(tab => tab.slug === activeTab).content}
      </TabContent>

      <Centered>
        <BackToTop onClick={scrollToTop} data-qa={"BackToTopButton"}>
          <BackToTopText>&nbsp;Back to top&nbsp;</BackToTopText>
        </BackToTop>
      </Centered>

    </div>
  )
}
