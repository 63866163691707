import React from "react"
import Header from "../components/header"
import MainContent from "../components/main-content"
import useSiteMetadata from "../hooks/use-site-metadata"
import getUserContent from "../../user-content"

export default function TabPage(props) {
  const siteMetadata = useSiteMetadata()
  const userContent = getUserContent()
  const tabs = userContent.tabs
  const TabPageSplash = userContent.TabPageSplash
  const activeTab = props.tab || tabs[0].slug

  return (<>
    <span role="complementary">
      <Header
        title={siteMetadata.businessName}
        url={`${siteMetadata.siteUrl}/${props.tab}`}
      />
    </span>

    <TabPageSplash activeTab={activeTab} />

    <div role="main">
      <MainContent tab={activeTab} />
    </div>
  </>)
}
